/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.text-center {
    text-align: center;
}

.btn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    width: 300px;
}
.btn-primary {
    background-color: #ed8b00 !important;
    border-color: #ed8b00 !important;
}

.col-custom-margin {
    margin-bottom: 10px;
    padding-left: 0;
}

a.download-link {
    color: #ed8b00;
}

.filepond--root {
    font-size: 1.25rem;
}

